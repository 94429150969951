import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLFormElement> {
  static targets = ["input"];

  declare inputTarget: HTMLInputElement;

  connect() {
    this.element.addEventListener("submit", this.handleSubmit.bind(this));
  }

  handleSubmit(event: Event) {
    event.preventDefault();

    const trimmedValue = this.inputTarget.value.trim();
    this.inputTarget.value = trimmedValue;

    this.element.requestSubmit();
  }

  disconnect() {
    this.element.removeEventListener("submit", this.handleSubmit.bind(this));
  }
}
