import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "button"];

  declare inputTarget: HTMLInputElement;
  declare buttonTarget: HTMLButtonElement;

  connect(): void {
    this.toggleButton();
  }

  toggleButton(): void {
    const hasContent: boolean = this.inputTarget.value.trim().length > 0;
    this.buttonTarget.disabled = !hasContent;
    this.buttonTarget.setAttribute("aria-disabled", String(!hasContent));
  }

  onInput(): void {
    this.toggleButton();
  }
}
