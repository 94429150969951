import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loading"];
  static classes = ["hidden"];

  declare loadingTarget: SVGElement;
  declare hiddenClass: string;

  show(): void {
    this.loadingTarget.classList.remove(this.hiddenClass);
  }

  hide(): void {
    this.loadingTarget.classList.add(this.hiddenClass);
  }
}
